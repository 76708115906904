


(() => {

  const flyoutMenuBtn = document.querySelector('#flyout-menu-btn')
  flyoutMenuBtn?.addEventListener('click', function () {
    toggleMenu();
  });

  flyoutMenuBtn?.addEventListener('keyup', function (event) {
    if ([13, 32].includes(event.keyCode)) {
      toggleMenu()
    }
  });

  const flyoutMenuLinks = document.querySelectorAll('#flyout-menu a')

  flyoutMenuLinks.forEach(fml => fml.addEventListener('click', function () {
    toggleMenu();
  }))

  function toggleMenu() {
    if (document.body.classList.contains('menu-is-open')) {
      document.body.classList.remove('menu-is-open');
      document.body.classList.add('menu-is-close');
    }
    else {
      document.body.classList.add('menu-is-open')
      document.body.classList.remove('menu-is-close');
    }
    toggleHidden(document.querySelector('#flyout-menu'));
  }

  /**
   *
   * @param {HTMLElement} element
   */
  function toggleHidden(element) {
    if (!element) return;
    const ariaValue = element.getAttribute("aria-hidden");
    element.setAttribute("aria-hidden", ariaValue == "true" ? "false" : "true");
  }

})();